import React, { useEffect, useContext } from 'react';
import { AppContext, App } from '../components/app';
import { Link, navigate } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import Menu from '../components/menu';
import Footer from '../components/footer';

const ContactPage = ({ location }) => {
    const context = useContext(AppContext);
    const { state, dispatch } = context;

    useEffect(() => {
        if (!state || !state.affiliateId) {
            navigate('/login');
        }
    }, []);

    if (!state || !state.affiliateId) {
        return <div></div>;
    }

    return (
        <Layout>
            <Menu location={location} />

            <h1 className="huge nohyphens">Contact</h1>
            <p>
                Please feel free to contact us at anytime! We like what we do,
                and we do it for you.
            </p>
            <p className="link">
                <a
                    href="mailto:&#x73;&#x75;&#x70;&#x70;&#x6F;&#x72;&#x74;&#x40;&#x62;&#x63;&#x61;&#x73;&#x68;&#x34;&#x79;&#x6F;&#x75;&#x2E;&#x63;&#x6F;&#x6D;"
                    className="underline"
                    title="Contact us"
                >
                    &#x73;&#x75;&#x70;&#x70;&#x6F;&#x72;&#x74;&#x40;&#x62;&#x63;&#x61;&#x73;&#x68;&#x34;&#x79;&#x6F;&#x75;&#x2E;&#x63;&#x6F;&#x6D;
                </a>
            </p>
            <p className="padding-bottom-0">skype: kutloch2</p>
            <div className="spacer spacer-80">&nbsp;</div>
            <img
                src={require('../images/maria-003.jpg')}
                width="100%"
                height="auto"
                alt="W4B"
            />
            <Footer />
        </Layout>
    );
};

export default ContactPage;
